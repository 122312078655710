import React, { useContext, useEffect } from "react"
import Layout from "layout"
import Container from "layout/Container"
import Hero from "layout/Hero"
import Button from "elements/Button"

import CompleteProfileModal from "../../../Consultations/Home/CompleteProfileModal"

import HomeHowItWorks from "../HomeHowItWorks"
import useHomeImages from "../../hooks/useHomeImages"

import { isBrowser, isObjectEmpty } from "services/general"
import { hasSignedInUser, getSignedInUser } from "components/Auth/services/user"

import { AppContext } from "context/AppContext"

import { GATSBY_TELECONSULT_WEBSITE_URL } from "gatsby-env-variables"

const Home = (props) => {
  let { pageContext } = props
  const { dispatch } = useContext(AppContext)
  const data = useHomeImages()
  const heart = data.heart.childImageSharp.fluid.src

  useEffect(() => {
    if (isBrowser()) {
      dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
      if (hasSignedInUser() && isObjectEmpty(getSignedInUser()?.userData)) {
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: <CompleteProfileModal nextPath="/enrollment" />,
          },
        })
      }
    }
  }, [dispatch])

  const handleOnClick = ({ name, url }) => {
    // urls will be placed in env after features completed
    if (isBrowser()) {
      localStorage.setItem("url", url)
      localStorage.setItem("program", name)
    }

    switch (true) {
      case hasSignedInUser() && isObjectEmpty(getSignedInUser()?.userData):
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold is-size-5`,
            content: <CompleteProfileModal nextPath="/enrollment" />,
          },
        })
        break

      case !!hasSignedInUser():
        window.location.href = `${GATSBY_TELECONSULT_WEBSITE_URL}booking/[$URL]/intake?program=[$PROGRAM]`
          .replace("[$URL]", url)
          .replace("[$PROGRAM]", name)
        break
      default:
        window.location.href = `/sign-in?program=[$PROGRAM]`.replace(
          "[$PROGRAM]",
          name
        )
        break
    }
  }

  return (
    <Layout
      display={{ helpCenterBanner: false, footer: true }}
      seoTitle="Teleconsult"
      pageContext={pageContext}
    >
      <Hero
        color="light"
        background={{
          image: heart,
          position: "center",
        }}
      >
        <Container isCentered desktop={10} fullhd={8}>
          <Container mobile={12} tablet={8} desktop={8} padding={0}>
            <div className="my-7">
              <h1 className="title is-size-1 has-text-primary">
                {pageContext?.module?.title}
              </h1>

              <p className="subtitle is-size-4 pt-2">
                {pageContext?.module?.subtitle}
              </p>

              <Button
                color="primary"
                size="medium"
                onClick={() =>
                  handleOnClick({
                    name: "Mental Health",
                    url: "mind",
                  })
                }
              >
                Request Teleconsult
              </Button>
            </div>
          </Container>
        </Container>
      </Hero>
      <HomeHowItWorks />
    </Layout>
  )
}

export default Home
