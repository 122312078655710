import React, { Fragment } from "react"

import Container from "layout/Container"
import ProcessArrow from "elements/ProcessArrow"
import MediaBox from "elements/MediaBox"

import useHomeImages from "../hooks/useHomeImages"
import howProgramWorks from "../utils/howProgramWorks.json"

const HomeHowItWorks = () => {
  const data = useHomeImages()
  return (
    <Container isCentered desktop={10} fullhd={8}>
      <h3 className="my-4 has-text-centered has-text-primary is-size-1">
        Here's how it works
      </h3>
      <div className="columns is-centered mb-3">
        {howProgramWorks.map((item, index) => (
          <Fragment key={index}>
            <MediaBox
              title={
                <h3 className="has-text-primary is-size-3">{item?.title}</h3>
              }
              image={{
                ...data[item?.imageName]?.childImageSharp?.fixed,
                alt: item.imageName,
              }}
            >
              <p className="has-text-centered is-size-5">{item?.description}</p>
            </MediaBox>
            {index < howProgramWorks?.length - 1 && (
              <ProcessArrow arrowImage={data.arrow.childImageSharp?.fixed} />
            )}
          </Fragment>
        ))}
      </div>
    </Container>
  )
}

export default HomeHowItWorks
