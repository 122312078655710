import React from "react"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * A box section used in colums
 * @param {children}
 * @param {string} title string
 * @param {object} image settings for the image (src, alt)
 */

const MediaBox = ({ image, title, children }) => {
  return (
    <div
      className={classNames(
        "column is-3-fullhd is-3-desktop px-2 pb-4 has-text-centered",
        styles["mediaBox"]
      )}
    >
      <div className="mediaBox__imageContainer">
        <img
          src={image?.src}
          alt={`${image?.alt}`}
          className={classNames(styles["mediaBox__image"])}
          width={image?.width || "50%"}
        />
      </div>
      <h3 className={styles["mediaBox__title"]}>{title}</h3>
      <p className="has-text-left">{children}</p>
    </div>
  )
}

export default MediaBox
